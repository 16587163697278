<template>
 <div class="vue-dropdown default-theme" v-show-extend="show">
  <div class="search-module clearfix" v-show="itemlist.length">
   <input class="search-text" 
   @keyup='search($event)' :placeholder="placeholder" />
  </div>
  <ul class="list-module">
   <li v-for ="(item,index) in datalist" @click="appClick(item)" 
   :key="index">
    <span class="list-item-text">{{item.name}}</span>
   </li>
  </ul>
  <div class="tip_nodata" v-show="!length">{{nodatatext}}</div>
 </div>
</template>

<script>
import { toRefs,reactive,computed,watch} from 'vue'
 export default {
   setup(props, context){
    const state = reactive({
      datalist: props.itemlist,
    });
    const length = computed(() => {
      return state.datalist.length
    });
    const appClick = (data) => {
      context.emit('item-click',data);
    };
    const search = (e) => {
      let vm = props.itemlist;
      let searchvalue = e.currentTarget.value;
      state.datalist = vm.filter(function(item){
        return item.name.indexOf(searchvalue) != -1;
      });
    };
    watch(()=>props.itemlist, (val) => {
      state.datalist = val.concat();
    })
   return {
    ...toRefs(state),
    length,
    appClick,
    search
   }
  },
  props:{
   'show':{//用于外部控制组件的显示/隐藏
    type:Boolean,
    default:true
   },
   'itemlist':Array,
   'placeholder':{
        type:String,
        default:'搜素'
   },
   'nodatatext':{
        type:String,
        default:'没有数据~'
   },
  },
//指令
  directives:{
   'show-extend':function(el,binding){//bind和 update钩子
    let value = binding.value,searchInput = null;
    if(value){
     el.style.display='block';
    }
    else{//隐藏后，恢复初始状态
     el.style.display='none';
     searchInput = el.querySelector(".search-text");
     searchInput.value = '';
    }
   }
  },
 }
</script>

<style lang="less" scoped>
 .vue-dropdown.default-theme {
  display: none;
  width: 72vw;
  // margin: 0 auto;
  padding: 4vw;
  z-index:10;
  // box-shadow: 0px 0px 10px #ccc;
  &._self-show {
   display: block!important;
  }

  .search-module {
   position: relative;
   .search-text {
    width: 67vw;
    height: 9vw;
    padding: 0 2vw;
    border-radius: 0.5em;
    box-shadow: none;
    border: 1px solid #ccc;

    &:focus {
     border-color: #2198f2;
    }
   }

   .search-icon {
    position: absolute;
    top: 24%;
    right: 0.5em;
    color: #aaa;
   }

  }

  .list-module {
   width: 100%;
   height: 60vh;
   overflow-y: auto;
   li {
    &._self-hide {
     display: none;
    }
    margin-top: 0.5em;
    padding: 0.5em;
    &:hover {
     cursor:pointer;
     color: #fff;
     background: #00a0e9;

    }
   }
  }
 }
 .tip_nodata {
  position: absolute;
  top: 13vw;
  font-size: 4vw;
  margin-top: 1em;
  color: #666;
 }
</style>