<template>
  <div class="auth">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">实名认证</div>
    <div class="card">
        <div class="step_lines">
            <span class="active">1</span>
            <div :class="[ 'line', step>1 ? 'active': '']"></div>
            <span :class="[ step>1 ? 'active': '']">2</span>
            <div :class="[ 'line', step>2 ? 'active': '']"></div>
            <span :class="[ step>2 ? 'active': '']">3</span>
            <div :class="[ 'line', step>3 ? 'active': '']"></div>
            <span :class="[ step>3 ? 'active': '']">4</span>
        </div>
        <div class="step_text">
            <span class="active">企业认证</span>
            <span :class="[ step>1 ? 'active': '']">汇款申请</span>
            <span :class="[ step>2 ? 'active': '']">打款认证</span>
            <span :class="[ step>3 ? 'active': '']">认证完成</span>
        </div>
    </div> 
    <div class="auth-card" v-if="step == 1">
        <div class="catalog">
            <p>企业名称</p>
            <input type="text" :value="companyName" disabled>
        </div>
        <div class="catalog">
            <p>统一社会信用代码</p>
            <input type="text" :value="creditCode" disabled>
        </div>
        <div class="catalog">
            <p>法人姓名</p>
            <input type="text" placeholder="输入法人姓名" v-model="legalPersonName" maxlength="50">
        </div>
        <div class="catalog">
            <p>法人身份证</p>
            <input type="text" placeholder="输入法人身份证号码" v-model="idcard" maxlength="18">
        </div>
        <!-- <div class="catalog">
            <p>&nbsp;</p>
            <div class="add_pic">
                <img src="@/assets/img/add_big.png" alt="">
                <p>企业法人营业执照</p>
            </div>
        </div> -->
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(2)">下一步</div>
        </div>

    </div>
    <div class="auth-card" v-if="step == 2">
        <div class="catalog">
            <p>企业对公账户名称</p>
            <input type="text" placeholder="输入企业对公账户名称" v-model="accountName" maxlength="128">
        </div>
        <div class="catalog">
            <p>企业对公账户号码</p>
            <input type="number" placeholder="输入企业对公账户号码" v-model="accountNumber" oninput="if(value.length>32) value=value.slice(0,32)">
        </div>
        <!-- <div class="catalog">
            <p>开户行所在地市</p>
            <div class="select">
            <select>
                <option value=""></option>
                <option value="1">南宁市</option>
                <option value="2">柳州市</option>
           </select>
            </div>
        </div> -->
        <div class="catalog">
            <p>企业对公账户开户行</p>
            <div class="select">
                <div class="input" @click="dropDownShow">
                    <span v-if="branchName.length<1">选择具体开户行</span>
                    <span v-else class="black-font">{{branchName}}</span>
                </div>
            </div>            
        </div>
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(1)">上一步</div>
            <div class="auth-btn" @click="setApply2()">提交申请</div>
        </div>

    </div>
    <div class="card tips" v-if="step == 3">
            汇款申请已提交，请留意银行卡金额变化，有效时间48小时。
    </div>
    <div class="auth-card" v-if="step == 3">        
        <div class="catalog">
            <p>入账金额(分)</p>
            <input type="number" placeholder="输入入账金额" v-model="amount" oninput="if(value.length>2) value=value.slice(0,2)">
        </div>
       <div class="footer-btn">
            <div class="auth-btn" @click="setApply3()">提交认证</div>
            <router-link to="/auth" replace class="auth-btn">返回</router-link>            
        </div>
    </div>
    <div class="auth-card" v-if="step == 4">
        <div class="pass">
            <img src="../../assets/img/tongguo.png" alt="">
            <p class="p1">&nbsp;&nbsp;&nbsp;恭喜您！</p>
            <p class="p2">您的实名认证已通过</p>
        </div>
        <div class="footer-btn">
            <router-link to="/auth" replace class="auth-btn">返回</router-link>
        </div>
    </div>
    <van-popup v-model:show="dropDownBool">
        <div class="pop">
            <Dropdown @item-click="dropDownClick"
            :itemlist="openBankList"
            nodatatext="没有搜索到网联支持银行~"
            placeholder="搜素银行名称"></Dropdown>
        </div> 
    </van-popup>

  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { ref,reactive,toRefs,toRaw } from 'vue';
import { apiGetEnterInfo,apiGxiAuth } from '../../api/axios';
import { Dialog,Toast } from 'vant';
import { useStore } from 'vuex';
import Dropdown from '@/components/DropDown.vue';
import {openBankListData} from '@/hooks/openBankList.js'
export default {
   setup() {
        let router = useRouter();
        let store = useStore();
        let openBankList = openBankListData();
        let routerStep = router.currentRoute.value.query.num;
        const step = ref(1);
        if(routerStep==='3') {
            step.value = 3;
        };
        const dropDownBool = ref(false);
        const setStep = (val) =>{
            if(!state.legalPersonName) {
                Toast('请先输入法人姓名');
                return
            }
            if(state.idcard.length < 15) {
                Toast('请先输入正确的法人身份证');
                return
            }
            step.value = val;
        };
        const state = reactive({
            idtype: '01',
            companyName: '',
            creditCode: '',
            legalPersonName: '',
            idcard: '',
            accountName: '',
            accountNumber: '',
            bankid: '',
            branchName: ''
        });
        const amount = ref('');
        apiGetEnterInfo({}).then(res => {                 
             if(res.code == '1'){
                 state.companyName = res.info.enterBaseInfo.enterName;
                 state.creditCode = res.info.enterRegisterInfo.uniscid;
              }
              else {
                Toast(res.msg);
              }
          });
        const setApply2 = () =>{
            if(!state.accountName) {
                Toast('请先输入企业的对公账户名称');
                return
            }
            if(state.accountNumber < 1) {
                Toast('请先输入企业的对公账户号码');
                return
            }
            if(state.bankid.length < 1) {
                Toast('请先选择企业的对公账号开户行');
                return
            }
            let obj2 = toRaw(state);
            let time2 = Date.parse(new Date()).toString();
            obj2.accountNumber = obj2.accountNumber.toString();
            obj2.timestap = time2;
            obj2.reqType = "C0301";
            // console.log(obj2);
            // step.value=3;
            console.log(obj2)
            apiGxiAuth(obj2).then(res => {   
                console.log(res)        
                    if(res.code === '1'){
                        // authInfo.value = res.info.info;
                        if(res.info.c_code === '0') {
                            // Dialog.alert({
                            // message: '汇款申请已提交，请留意银行卡金额变化，有效时间 48小时',
                            // theme: 'round-button',
                            // }).then(() => {
                            //     router.push({
                            //         path:'/auth',
                            //         replace: true
                            //     });
                            // });
                            step.value = 3;
                        }else {
                            Toast.fail(res.info.c_msg);
                        }                        
                    }
                    else {
                        Toast.fail(res.msg);
                    }
            });
            
        };

        let lf = store.state.loginInfo;
        const setApply3 = ()=> {
            if(!(/(^[1-9]\d*$)/.test(amount.value))) {
                Toast('请输入0~99的正整数(金额单位为"分")');
                return;
            };
            let time3 = Date.parse(new Date()).toString();
            let obj3= {
                reqType: '130601',
                amount: amount.value.toString(),
                timestap: time3
            }
            console.log(obj3,333);
            Toast.loading({
                duration: 0,
                message: '提交认证中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
             apiGxiAuth(obj3).then(res => {
                    Toast.clear();              
                    if(res.code === '1'){
                        // authInfo.value = res.info.info;
                        if(res.info.c_code === '0') {
                            step.value = 4;
                            lf.isOrgAuth = '1';
                            localStorage.setItem("gxrToken", JSON.stringify(lf));
                            store.dispatch('setToken',lf);
                        }else {
                            Toast.fail(res.info.c_msg);
                        }
                        
                    }
                    else {
                        Toast.fail(res.msg);
                    }
            });
        };

        const dropDownClick = (e) => {
            state.bankid = e.id;
            state.branchName = e.name;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            // 兼容IE
            window.scrollTo(0, 0);
            dropDownBool.value = false;
        }
        const dropDownShow = () =>{
            document.body.scrollTop = 1;
            document.documentElement.scrollTop = 1;
            // 兼容IE
            window.scrollTo(0,1);
            dropDownBool.value = true;
        }

     return {
         store,
         step,
         dropDownBool,
         ...toRefs(state),
         openBankList,
         setStep,
         setApply2,
         amount,
         setApply3,
         dropDownClick,
         dropDownShow
     }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },
  components: {
    Dropdown
  },

}
</script>
<style lang="less" scoped>
.auth {
    min-height: calc(100vh + 1px);
    padding-top: 12.5vw;
    box-sizing: border-box;
    // background-color: #fff;
    .tips {
        text-align: center;
        font-family: cursive;
        font-size: 5vw;
        font-weight: 600;
    }
    .step_lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4vw;
        span {
            text-align: center;
            line-height: 6vw;
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            color: rgb(128, 127, 127);
            background-color: #ccc;
        }
        .line {
            border-top: 1px solid #ccc;
            flex: 1;
        }
        .active {
        color: #fff;
        background-color: #ff9900;
        border-color: #ff9900;
        }
    }
    .step_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        margin-top: 3px;
        color: #666;
        .active {
            color: #ff9900;
        }
    }    
    .auth-card {
        margin: 4vw;
        background-color: #fff;
        border-radius: 1.5vw;
        padding: 4vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 24vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                
                .key {
                    color: #ff6600;
                }
            }
            // p:last-child {
            //     width: 57vw;
            //     font-size: 4vw;
            //     padding-left: 4vw;

            // }
            .add_pic {
                width: 57vw;
                height: 39vw;
                font-size: 4vw;
                // padding-left: 4vw;
                margin-left: 4vw;
                border-radius: 1vw;
                background-color: #f4f8fe;
                text-align: center;
                img {
                    width: 13vw;
                    margin: 6vw 0 2vw;
                }
                p {
                    color: #336699;
                }

            }
            input,select {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                ::-webkit-calendar-picker-indicator{
                    display: none;
                    -webkit-appearance: none;
                }
                ::-webkit-calendar-picker-indicator {
                    opacity: 100;
                }
            }
            ::-webkit-input-placeholder {
              color: #7f9abb;
            }
            ::-moz-placeholder {
              color: #7f9abb;
            }
            :-ms-input-placeholder {
              color: #7f9abb;
            }
            :-moz-placeholder {
              color: #7f9abb;
            }
            .input {
                display: inline-block;
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                color: #7f9abb;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                .black-font {
                    color: #333
                }
            }

        }
        .pass {
            text-align: center;
            margin: 4vw 0;
            img {
                width: 15vw;
            }
            .p1 {
                font-weight: 600;
                font-size: 5.5vw;
            }
            .p2 {
                font-size: 4vw;
            }
        }
        .footer-btn {
            display: flex;
            justify-content: center;
            .auth-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 1.5vw;
            border-radius: 7vw;
            border: 0;
            margin: 5vw 2vw 0;
            width: 25vw;
            text-align: center;
            letter-spacing: 1px;
        }   
    

        }
        
    }
    .pop {
        width: 80vw;
    }
    
}
</style>
